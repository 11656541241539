import React from 'react';
import styles from '../PrivatePolicy/PrivatePolicy.module.scss';
import { Link } from 'react-router-dom';

const TermsOfUse: React.FC = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to={'/home'}>
          <img
            className={styles.logo}
            src={'/icon/voss_logo.png'}
            alt={'voss_logo'}
          />
        </Link>
      </header>
      <main className={styles.content}>
        <h1 className={styles.title}>이용약관</h1>
        <div className={styles.policyContainer}>
          <div className={styles.policyHeader}>
            <h2>이용약관</h2>
            <p>쉽고 편리한 온라인 서비스를 제공하겠습니다.</p>
          </div>
          <div className={styles.policyContent}>
            <ol>
              <li>
                <h4>제1장 총칙</h4>
                <ol>
                  <li>
                    <h5>제1조 (목적)</h5>
                    <p>
                      본 약관은 VOSS(이하 "회사")가 제공하는 VOSS플랫폼 관련
                      상담 관리 플랫폼(이하 "서비스")를 이용함에 있어 회사와
                      회원과의 권리, 의무, 이용조건 및 절차에 관한 사항과 기타
                      이용에 필요한 사항 등을 규정함을 목적으로 합니다.
                    </p>
                  </li>
                  <li>
                    <h5>제2조 (약관의 효력 및 변경)</h5>
                    <ol>
                      <li>
                        ① 본 약관은 서비스를 이용하는 회원에 대하여 그 효력을
                        발생합니다.{' '}
                      </li>
                      <li>
                        ② 본 약관의 내용은 VOSS 플랫폼 서비스
                        사이트(https://voss.com)에 게시하거나 기타의 방법으로
                        회원에게 공지하고, 이에 동의한 회원이 서비스에
                        가입함으로써 효력이 발생합니다.{' '}
                      </li>
                      <li>
                        ③ 회사는 본 약관의 내용과 회사의 상호, 영업소 소재지,
                        대표자 성명, 사업자 등록번호, 연락처(전화, 전자우편 주소
                        등) 등을 회원이 알 수 있도록 사이트의 초기 서비스 화면에
                        게시합니다.
                      </li>
                      <li>
                        ④ 본 약관은 회사가 필요하다고 인정되는 경우 대한민국
                        법령의 범위 내에서 개정할 수 있으며, 회사가 본 약관을
                        개정할 경우에는 적용예정일 및 개정사유를 명시하여 현행
                        약관과 함께 서비스 초기화면에 그 적용예정일 7일 전부터
                        공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는
                        경우에는 그 적용예정일 30일 전부터 공지하며, 공지하는 것
                        외에 전자우편 발송 등 전자적 수단을 통해 별도로
                        통지합니다.
                      </li>
                      <li>
                        ⑤ 회원은 개정된 약관에 대해 동의하지 않을 권리가 있으며,
                        개정된 약관에 동의하지 않을 경우 이용계약을 해지할 수
                        있습니다. 단, 회사가 제4항에 따라 변경 약관을 공지 또는
                        통지하면서, 회원이 개정된 약관의 적용일 전까지
                        거부의사를 표시하지 아니하는 경우 약관의 변경에 동의한
                        것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이
                        명시적으로 거부의사를 표시하지 아니하였다면 회원이 변경
                        약관에 동의한 것으로 간주합니다. 회원은 변경된 약관에
                        동의 하지 않을 경우 서비스 이용을 중단하고 이용계약을
                        해지할 수 있습니다.
                      </li>
                      <li>
                        ⑥ 본 약관에 동의하는 것은 회사가 운영하는 서비스
                        사이트를 정기적으로 방문하여 약관의 변경사항을 확인하는
                        것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지
                        못하여 발생하는 회원의 피해에 대해 회사는 책임을 지지
                        않습니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>제3조 (약관의 해석 및 약관 외 준칙) </h5>
                    <ol>
                      <li>
                        ① "회사"는 개별 서비스에 대해서 별도의 이용약관 및
                        운영정책을 둘 수 있으며, 해당 내용이 본 약관과 상충할
                        경우에는 개별 서비스 별 이용약관(이하 "개별 약관") 또는
                        운영정책을 우선하여 적용합니다.
                      </li>
                      <li>
                        ② 회사는 개별 약관 등을 제2조 제3항의 방법에 의하여
                        공지할 수 있으며, 회원은 운영정책을 숙지하고 준수하여야
                        합니다.{' '}
                      </li>
                      <li>
                        ③ 본 약관에 명시되지 사항은 개별 약관 또는 운영정책,
                        관련 법령, 상관례에 따릅니다.{' '}
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>제4조 (용어의 정의) </h5>
                    <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                    <ol>
                      <li>
                        "회원"이라 함은 회사에서 제공하는 사이트에 접속하여 본
                        약관과 개인정보처리방침에 동의하고, 아이디와 비밀번호를
                        발급받아 회사가 제공하는 서비스를 이용하는 자를
                        말합니다.{' '}
                      </li>
                      <li>
                        "VOSS 플랫폼"이라 함은 서비스의 제공을 위하여 회사가
                        구축한 온라인 교육 시스템을 말합니다.{' '}
                      </li>
                      <li>
                        "사이트"라 함은 VOSS 플랫폼 서비스를 제공하기 위해
                        회사가 운영하는 웹사이트(https://voss.com)를 말합니다.
                      </li>
                      <li>
                        "서비스"라 함은 회사가 회원에게 VOSS 플랫폼 내에서
                        제공하는 통화, 통계, 업무처리 등 모든 서비스를 말합니다.
                      </li>
                      <li>
                        "이메일(Email)"라 함은 회사가 회원의 식별과 회원의
                        서비스 이용을 위해 필요한 식별자를 의미하며, 회원이
                        지정하여 가입한 이메일을 의미합니다.
                      </li>
                      <li>
                        "비밀번호(Password)"라 함은 회원 비밀 보호를 위해 회원
                        자신이 정한 문자 또는 숫자의 조합을 의미하며, 아이디
                        별로 하나의 비밀번호만 설정, 이용 가능합니다.
                      </li>
                      <li>
                        "지식재산권"이란 발명, 아이디어, 창작, 컴퓨터프로그램을
                        포함한 저작물, 데이터베이스, 영업비밀 및 노하우 등 법상
                        보호 가능한 일체의 무형재산에 대한 권리들을 말하며,
                        출원, 신청, 등록 유무를 불문합니다.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h4>제2장 서비스 이용계약</h4>
                <ol>
                  <li>
                    <h5>제5조 (이용계약의 성립, 이용신청 및 승낙) </h5>
                    <ol>
                      <li>
                        ① 이용계약은 회원이 되고자 하는 자가 약관의 내용에
                        대하여 동의를 한 다음 회원가입 신청을 하고 회사가 이러한
                        신청에 대하여 승낙함으로써 체결됩니다.{' '}
                      </li>
                      <li>
                        ② 회원이 되고자 하는 자는 사이트에 가입 시(또는 교육등록
                        신청 시) 회사에서 제공하는 회원가입(또는 교육등록)
                        신청양식에 따라 필요사항(성명, 전자우편 주소, 핸드폰
                        번호, 아이디 등)을 기재하여야 합니다.{' '}
                      </li>
                      <li>
                        ③ 회사는 회원이 되고자 하는 자에게 전자우편 주소와
                        핸드폰 번호를 통한 인증 및 기타 본인을 확인할 수 있는
                        인증절차를 요구할 수 있습니다. 이 경우 회원이 되고 자
                        하는 자는 해당 인증을 수행하는 등 가입절차를 완료하여야
                        합니다.{' '}
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>제6조 (이용신청에 대한 승낙의 제한) </h5>
                    <ol>
                      <li>
                        ① 회사는 다음의 각 호에 해당하는 이용신청에 대하여는
                        승낙을 제한할 수 있으며, 이 사유가 해소될 때까지 승낙을
                        유보하거나 승낙한 이후에도 이용계약을 해지할 수
                        있습니다.
                        <ol>
                          <li>
                            기술상 문제가 있거나 서비스 관련 설비 용량이 부족한
                            경우{' '}
                          </li>
                          <li>
                            가입신청자가 본 약관에 따라 이전에 회원자격을 상실한
                            적이 있는 경우. 단, 회사의 재가입 승낙을 얻을 경우는
                            예외로 함{' '}
                          </li>
                          <li>
                            실명이 아니거나 다른 사람의 명의사용 등 회원 등록 시
                            허위로 신청하는 경우
                          </li>
                          <li>
                            회원 등록사항을 누락하거나 오기하여 신청하는 경우
                          </li>
                          <li>
                            서비스 이용목적 또는 이용행위가 법령에 위반되거나
                            제3자의 권리를 침해할 우려가 있는 경우
                          </li>
                          <li>
                            악성 프로그램 및 버그를 이용하거나 시스템 취약점을
                            악용하는 등 부정한 방법을 서비스에 사용한 경우
                          </li>
                          <li>
                            회사의 사전 동의 없이 영리를 추구할 목적으로
                            서비스를 이용하고자 하는 경우
                          </li>
                          <li>
                            사회의 안녕질서 또는 미풍양속을 저해하거나 저해할
                            목적으로 신청한 경우
                          </li>
                          <li>
                            가입신청자의 귀책사유로 인하여 승인이 불가능하거나
                            기타 규정한 제반 사항을 위반하여 신청하는 경우{' '}
                          </li>
                          <li>
                            기타 회사가 정한 이용신청 요건이 만족되지 않았을
                            경우
                          </li>
                        </ol>
                      </li>
                      <li>
                        ② 이용신청의 승낙을 하지 아니하거나 유보한 경우 회사는
                        원칙적으로 이를 가입신청자에게 알리도록 합니다.{' '}
                      </li>
                      <li>
                        ③ 회사는 회원에 대하여 회사 정책에 따라 회원 유형별로
                        구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여
                        이용에 차등을 둘 수 있습니다.{' '}
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>제7조 (회원정보 변경 및 보호) </h5>
                    <ol>
                      <li>
                        ① 회원은 회원정보 관리화면을 통하여 언제든지 본인의
                        정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를
                        위해 아이디는 수정이 불가능하며, 부득이한 사유로
                        수정하고자 하는 경우 이용계약을 해지하고 사이트에 재가입
                        하여야 합니다.{' '}
                      </li>
                      <li>
                        ② 회원은 이용 신청 시 기재한 사항이 변경되었을 경우 즉시
                        온라인으로 수정을 하거나 회사가 정한 별도의 양식 및
                        방법으로 변경 사항을 알려야 합니다.
                      </li>
                      <li>
                        ③ 회사는 회원이 변경사항을 알리지 않아 발생한 불이익에
                        대하여 책임지지 않습니다.
                      </li>
                      <li>
                        ④ 회사는 개인정보 보호법, 정보통신망 이용촉진 및
                        정보보호 등에 관한 법률(이하 "정보통신망법") 등
                        관계법령이 정하는 바에 따라 회원의 정보를 보호하기 위해
                        노력합니다.
                      </li>
                      <li>
                        ⑤ 개인정보의 보호 및 이용에 대해서는 관계법령 및 회사의
                        개인정보 처리방침이 적용됩니다. 다만, 회사의 사이트
                        이외의 링크된 사이트에서는 회사의 개인정보 처리방침이
                        적용되지 않습니다.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h4>제 3 장 서비스의 이용</h4>
                <p>
                  본 장에서는 사이트를 통한 서비스의 제공 또는 이용에 관한
                  일반적인 사항을 규정하며, 서비스의 내용에 관한 세부적인 사항은
                  개별 약관, 운영정책 또는 서비스 화면 내에 공지한 바에
                  따릅니다.
                </p>
                <ol>
                  <li>
                    <h5>제8조 (서비스의 이용개시)</h5>
                    <ol>
                      <li>
                        ① 회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시
                        합니다.
                      </li>
                      <li>
                        ② 회사의 업무상 또는 기술상의 장애로 인하여 서비스를
                        개시하지 못하는 경우에는 사이트에 공시하거나 회원에게
                        이를 통지합니다.
                      </li>
                      <li>
                        ③ 회사는 특정한 서비스를 제공하기 위해 회원에게 별도
                        또는 추가적인 가입절차를 요청할 수 있으며, 이러한 특정
                        서비스를 이용 할 경우 해당 서비스에 대한 이용약관 등이
                        본 약관보다 우선 적용됩니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>제9조 (서비스의 사용 및 저장) </h5>
                    <ol>
                      <li>
                        ① 회사는 서비스의 이용에 관한 일반 관례 및 제한을 둘 수
                        있으며, 이를 통해 사이트 게시판 게시물, 업로드 영상물
                        또는 기타 게재된 내용이 서비스에 의해 보유되는 최대 일수
                        및 용량, 서비스 상의 서버에 할당되는 최대 디스크 공간 및
                        정해진 기간 동안 서비스에 접속할 수 있는 최대한의
                        수(최대 기간)에 대한 정책을 규정할 수 있습니다.
                      </li>
                      <li>
                        ② 제1항의 정책에 따라 게시판 게시물, 업로드 영상물 또는
                        기타 게재된 내용 등을 삭제하거나 저장하지 못한데 대해
                        회사는 아무런 책임을 지지 않습니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>제10조 (이용계약의 변경)</h5>
                    <p>
                      회원이 서비스 이용계약의 내용을 변경하고자 할 때에는
                      회사에 청구하여 승낙을 얻어야 합니다.
                    </p>
                  </li>
                  <li>
                    <h5>제11조 (서비스의 이용시간) </h5>
                    <ol>
                      <li>
                        ① 회사는 서비스를 연중무휴, 1일 24시간 제공하기 위하여
                        최대한 노력합니다. 다만, 회사의 업무상이나 기술상의 이유
                        등 불가피한 사유로 서비스가 일시 중지될 수 있고, 또한
                        운영상의 목적으로 회사가 정한 기간에는 서비스가 일시
                        중지될 수 있으며, 해당 내용은 서비스 내 공지합니다.
                      </li>
                      <li>
                        ② 제1항의 사항에 대하여 "회사"는 사전에 공지함이
                        원칙이나 불가피한 경우 사후에 공지할 수 있습니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>제12조 (이용의 정지)</h5>
                    <ol>
                      <li>
                        ① 회사는 회원의 서비스 이용내용이 다음 각호의 1 이상에
                        해당할 경우에는 일정한 기간을 정하여 이에 대한 개선을
                        요구할 수 있으며, 기간 내에 개선되지 않을 경우 사전통지
                        없이 서비스 이용을 정지할 수 있습니다.
                        <ol>
                          <li>
                            국익 또는 사회적 공익을 저해할 목적으로 이용되는
                            경우{' '}
                          </li>
                          <li>
                            범죄적 행위 및 미풍양속, 공공질서에 반하는 행위에
                            해당하는 경우{' '}
                          </li>
                          <li>
                            타인의 명예를 손상시키거나 타인에게 불이익을 주는
                            행위{' '}
                          </li>
                          <li>
                            안정된 서비스 운용을 저해할 수 있는 다량의 정보전송
                            및 광고성 정보를 전송 또는 매개하는 행위{' '}
                          </li>
                          <li>
                            전산, 정보기기의 오작동을 유발시킬 수 있는 컴퓨터
                            바이러스 프로그램을 유포하는 행위{' '}
                          </li>
                          <li>
                            과다 트래픽 발생이나 브로드 캐스팅으로 서비스 중인
                            네트워크에 장애 발생이 우려되는 경우{' '}
                          </li>
                          <li>
                            DDoS공격에 노출 또는 해킹되었거나 바이러스에 감염된
                            것으로 의심되는 경우{' '}
                          </li>
                          <li>
                            정보통신망법 등 관계법령이나 회사가 정한 이용조건에
                            위배되는 경우{' '}
                          </li>
                          <li>
                            회원이 공유한 파일에 악성 프로그램이 설치되어 다른
                            회원의 개인정보 유출 등 보안상 위험이 있는 경우 또는
                            정당한 사용권한 없는 상용 소프트웨어가 설치되어 있는
                            등 제3자의 지식재산권 침해와 관련한 분쟁이 발생할
                            우려가 있는 경우{' '}
                          </li>
                          <li>
                            기타 제22조에 정해진 회원의 의무를 위반한 경우{' '}
                          </li>
                        </ol>
                      </li>
                      <li>
                        ② 회사는 제1항 관련 회원의 귀책사유가 해소되었다고
                        판단된 때에는 지체 없이 이용 정지를 해제합니다.{' '}
                      </li>
                      <li>
                        ③ 회사는 제1항 관련 이용정지 이후 30일 내에 그 사유가
                        개선되지 않는 경우 30일간 유예기간을 거쳐 서비스
                        이용계약을 해지할 수 있습니다. 이 때 회사는 이 사실을
                        회원에게 서면 또는 유선 등의 방법으로 통보합니다.{' '}
                      </li>
                    </ol>
                  </li>
                </ol>
                <li>
                  <h5>제 13 조 (게시물 또는 내용물의 삭제) </h5>
                  <ol>
                    <li>
                      ① 회사는 회원이 서비스 내에 게시하거나 전달하는 모든
                      내용물(회원간 전달 포함)이 다음 각 호의 경우에 해당한다고
                      판단되는 경우 사전통지 없이 삭제할 수 있으며, 이에 대해
                      회사는 어떠한 책임도 지지 않습니다.
                      <ol>
                        <li>
                          회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전
                          승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는
                          내용인 경우{' '}
                        </li>
                        <li>
                          회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로
                          명예를 손상시키는 내용인 경우{' '}
                        </li>
                        <li>
                          음란물을 게재, 공개 하거나 음란사이트를 연결(링크)하는
                          경우{' '}
                        </li>
                        <li>
                          공공질서 및 미풍양속에 위반되는 내용의 정보, 문장,
                          도형 등의 유포에 해당하는 경우{' '}
                        </li>
                        <li>범죄적 행위에 결부된다고 인정되는 내용인 경우 </li>
                        <li>
                          회사 또는 제3자의 지식재산권 기타 권리를 침해하는
                          내용인 경우{' '}
                        </li>
                        <li>
                          제2항 소정의 세부 이용지침을 통하여 회사에서 규정한
                          게시기간을 초과한 경우{' '}
                        </li>
                        <li>
                          서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및
                          혼란을 유발시키는 내용이거나 컴퓨터 바이러스 감염
                          자료를 등록 또는 유포하는 경우{' '}
                        </li>
                        <li>
                          회사에서 공식적으로 인정한 경우를 제외한 서비스를
                          이용하여 상품을 판매하는 영업 활동 및 해킹, 광고를
                          통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어
                          불법배포를 하는 경우{' '}
                        </li>
                        <li>
                          법률에 의하거나 계약상 또는 위임에 의하여 전송할 수
                          있는 권리가 없는 내용을 게시, 게재, 전자메일 또는
                          여하한의 방법으로 전송하거나 공개하는 경우{' '}
                        </li>
                        <li>
                          회사에서 제공하는 서비스와 관련 없는 내용인 경우{' '}
                        </li>
                        <li>
                          불필요하거나 승인되지 않은 광고, 판촉물을 게재하는
                          경우{' '}
                        </li>
                        <li>
                          기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는
                          경우
                        </li>
                      </ol>
                    </li>
                    <li>
                      ② 회사는 게시물에 관련된 세부 이용지침을 별도로 정하여
                      시행할 수 있으며, 회원은 그 공개된 지침에 따라 각종
                      게시물(회원간 전달 포함)을 등록하거나 삭제하여야 합니다.{' '}
                    </li>
                    <li>
                      ③ 서비스 게시물 또는 내용물이 타인의 사생활 침해, 명예
                      훼손, 지식재산권 침해 등 타인의 권리를 침해하는 경우,
                      침해를 받은 자는 담당자(VOSS 그룹인재개발실 박은원 차장,
                      VOSS@voss.com)을 통하여 침해사실에 대한 소명 및 신고를
                      통하여 회사에 침해 중단에 필요한 조치를 요구할 수
                      있습니다.{' '}
                    </li>
                    <li>
                      ④ 전항의 요구가 있을 경우, 회사는 관련 법규에 따라
                      반박내용의 게재, 삭제, 임시조치 등의 필요한 조치를 취할 수
                      있으며 신청인 및 정보 게재자에게 전자우편 등의 방법으로
                      조치사항을 통보하며, 해당 게시판에 공지하는 등의 방법으로
                      이용자가 알 수 있도록 하여야 합니다.{' '}
                    </li>
                    <li>
                      ⑤ 권리의 침해 여부를 판단하기 어렵거나 이해당사자 간에
                      다툼이 예상되는 경우, 회사는 해당 정보에 대한 접근을
                      임시적으로 차단하는 조치(이하 "임시조치")를 취할 수
                      있으며, 임시조치의 기간은 30일 이내로 합니다.
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>제 14 조 (게시물의 저작권) </h5>
                  <ol>
                    <li>
                      ① 회사가 작성하여 사이트 또는 서비스에 게시한 저작물의
                      저작권은 회사 또는 회사가 제휴계약을 맺은 제3자에
                      귀속됩니다. 단, 회원이 게시한 저작물은 제외합니다.{' '}
                    </li>
                    <li>
                      ② 회원이 서비스 내에 게시한 게시물(회원간 전달 포함, 이하
                      같다.)에 대한 모든 권한과 책임은 회원 자신에게 있습니다.
                      회사와 다른 회원들은 회원이 게시한 게시물을 서비스 목적
                      범위 내에서 이용할 수 있으나 회원의 동의 없이 서비스 외
                      다른 목적으로 사용할 수 없습니다.{' '}
                    </li>
                    <li>
                      ③ 회원은 개별약관 등 및 관련 법령의 규정에서 별도로 정하지
                      않는 한 회원이 서비스에 게시물을 게시하거나 게시를 위해
                      회사에 제공함과 동시에 회사에게 서비스 제공을 위한 범위
                      내의 비독점적인 사용권을 부여한 것으로 간주 합니다.{' '}
                    </li>
                    <li>
                      ④ 회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권,
                      프로그램 저작권 등 지식재산권을 침해하더라도 이에 대한
                      민•형사상 등 일체의 책임을 부담하지 않습니다. 만일 회원이
                      타인의 지식재산권을 침해하였음을 이유로 회사가
                      타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은
                      회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한
                      경우 회원은 그로 인해 회사에 발생한 모든 손해를 배상하여야
                      합니다.
                    </li>
                    <li>
                      ⑤ 회사는 회원이 해지하거나 적법한 사유로 해지된 경우 해당
                      회원이 게시하였던 게시물을 삭제할 수 있습니다.{' '}
                    </li>
                    <li>
                      ⑥ 회원은 서비스에 게시된 자료 또는 서비스를 이용하여 얻은
                      정보를 회사 또는 이를 게시한 회원의 사전승낙 없이 영리
                      목적으로 이용 또는 복제, 송신, 출판, 배포, 방송하거나
                      제3자에게 이를 이용하게 할 수 없으며, 이를 위반할 경우
                      지식재산권 침해에 대한 책임을 부담할 수 있습니다.{' '}
                    </li>
                    <li>
                      ⑦ 서비스, 서비스와 관련된 소프트웨어와 후원 광고에
                      포함되어 있는 내용 및 서비스 또는 광고주를 통하여 회원에게
                      제공 되는 정보는 저작권, 상표권, 특허 등 제반 지식재산권
                      관련 법령에 의해 보호됩니다.{' '}
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>제 15 조 (서비스의 재판매 금지) </h5>
                  <p>
                    회원은 상업적인 목적을 위하여 서비스 자체, 서비스 이용 또는
                    서비스 내용의 일부 또는 전부를 복사, 복제, 판매, 재판매 또는
                    이용할 수 없습니다.{' '}
                  </p>
                </li>
                <li>
                  <h5>제 16 조 (영상서비스 이용관련) </h5>
                  <ol>
                    <li>
                      ① 회사는 이용자에게 안정적인 서비스를 제공하기 위해,
                      회사의 서비스 정책과 기술환경에 의하여 필요하다고 판단될
                      경우 플러그인 프로그램이나 기술을 변경할 수 있습니다.{' '}
                    </li>
                    <li>
                      ② 제공되는 영상서비스 중 일부 서비스는 회사에서 정한
                      회원정책상의 회원자격과 회원이 사용하고 있는 해당 유무선
                      기기의 종류와 사양에 따라 사용의 제한이 있을 수 있으며,
                      해당사항에 대한 안내는 해당 서비스의 사용안내 또는 공지를
                      통하여 통지합니다.{' '}
                    </li>
                    <li>
                      ③ 서비스에서 제공하는 영상 콘텐츠에 대해 정당한 권한이나
                      해당 프로그램 저작권자의 허락 없이 프로그램 등을 변경,
                      복제, 제거하거나 배포, 게시, 전송, 링크하는 경우나
                      저작권자의 실명 또는 익명을 변경 또는 은닉하거나 해당
                      프로그램의 제호를 변경하여 당사자의 허락 없이 게시, 게재할
                      수 없습니다.{' '}
                    </li>
                    <li>
                      ④ 회원은 게재한 소프트웨어 프로그램, 정보, 메시지, 데이터,
                      문서, 그림, 이미지, 문자, 사운드 등의 정보에 대하여 책임이
                      있으며, 저작권 침해나 기타의 불법으로 인해 발생하는 모든
                      피해에 대한 책임은 본인에게 있습니다.{' '}
                    </li>
                    <li>
                      ⑤ 본 조 제3항 및 제4항으로 인해 피해가 발생하는 경우
                      회사는 어떠한 책임도 부담하지 아니합니다.{' '}
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>제17조 (서비스의 변경 및 중지)</h5>
                  <ol>
                    <li>
                      ① 회사는 수시로 서비스의 향상, 회사의 정책, 제휴계약의
                      내용, 저작권자의 이용허락 범위 등에 따라 기존 서비스의
                      전부 또는 일부 내용을 변경할 수 있습니다. 이 경우 회사는
                      변경될 서비스의 내용 및 변경시점을 서비스 내에서 미리
                      공지합니다.{' '}
                    </li>
                    <li>
                      ② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는
                      일부를 제한하거나 중지할 수 있습니다.
                      <ol>
                        <li>
                          컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
                          통신의 두절 등의 사유가 발생한 경우{' '}
                        </li>
                        <li>
                          서비스를 위한 설비의 보수 등 공사로 인해 부득이한 경우{' '}
                        </li>
                        <li>
                          서비스 업그레이드 및 사이트 유지보수 등을 위해 필요한
                          경우{' '}
                        </li>
                        <li>
                          정전, 제반 설비의 장애 또는 이용량의 폭주 등으로
                          정상적인 서비스 이용에 지장이 있는 경우{' '}
                        </li>
                        <li>회원이 회사의 영업 활동을 방해하는 경우 </li>
                        <li>
                          회사의 경영상 판단 등 기타 서비스를 지속할 수 없는
                          중대한 사유가 발생하였을 경우{' '}
                        </li>
                        <li>
                          기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는
                          경우{' '}
                        </li>
                      </ol>
                    </li>
                    <li>
                      ③ 제2항에 의한 서비스 중지의 경우에는 회사가 그 내용을
                      사전에 "회원"에게 고지하거나, 사전 고지가 어려운 사항일
                      경우 사후에 고지할 수 있습니다.{' '}
                    </li>
                    <li>
                      ④ 회사는 서비스 제공에 필요한 경우 정기점검을 실시할 수
                      있으며, 정기점검 동안 서비스 제공을 일시적으로 중단할 수
                      있습니다. 정기점검 시간은 서비스 제공화면에 공지한 바를
                      따릅니다.{' '}
                    </li>
                    <li>
                      ⑤ 회사는 본 조의 사유로 서비스의 제공이 제한되거나
                      중지됨으로 인하여 "회원" 또는 제3자가 입은 손해에 대해
                      배상하지 않음을 원칙으로 합니다.{' '}
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>제18조 서비스의 일시 중지</h5>
                  <ol>
                    <li>
                      ① 회원이 서비스를 일정기간 사용하지 아니할 사유가 발생하여
                      이용을 일시 중지하고자 할 경우 회사에 요청할 수 있습니다.{' '}
                    </li>
                    <li>
                      ② 서비스의 일시 중지 회수는 1년에 3회 이내로 하며, 1회에
                      30일 이상 90일 이하의 기간(단, 연간 일시 중지 누적기간은
                      90일을 초과할 수 없음)으로 합니다. 단, 회사가 인정하는
                      정당한 사유가 있는 경우에는 예외로 합니다.{' '}
                    </li>
                    <li>
                      ③ 회원이 서비스를 재개하고자 할 경우에는 이를 회사에
                      알려야 하며, 회사는 이를 통지 받은 날로부터 2일 이내에
                      서비스를 재개해야 합니다.
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>제19조 (정보의 제공, 광고의 게재 및 하이퍼링크)</h5>
                  <ol>
                    <li>
                      ① 회사는 서비스의 운영과 관련하여 사이트, 서비스 화면,
                      SMS, 전자우편 등으로 적절하다고 판단되거나 활용가능성이
                      있는 광고 등을 게시할 수 있으며, 회원은 소정의 절차를 통해
                      제한적으로 이를 거부할 수 있습니다.{' '}
                    </li>
                    <li>
                      ② 회원이 서비스상에 게재되어 있는 광고를 이용하거나
                      서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로
                      교신 또는 거래를 하는 것은 전적으로 회원과 광고주간의
                      문제입니다. 만약 회원과 광고주간에 문제가 발생할 경우에도
                      회원과 광고주가 직접 해결해야 하며, 이와 관련하여 회사는
                      어떠한 책임도 지지 않습니다.{' '}
                    </li>
                    <li>
                      ③ 회사는 하이퍼링크 등으로 연결된 사이트가 독자적으로
                      제공하는 재화 용역에 관하여 회원 상호간 또는 회원과
                      제3자간 행하는 거래에 대해서 보증책임을 지지 않습니다.{' '}
                    </li>
                    <li>
                      ④ 회원은 관련 법령에 따른 서비스 이용 관련정보 및
                      고객문의에 대한 답변 등을 제외하고는 언제든지 정보제공을
                      위한 전자 우편의 수신을 거절할 수 있습니다. 다만, 수신거절
                      당시 이미 발송 중인 전자우편은 기술상의 이유로 발송이
                      중단되지 않을 수 있습니다.
                    </li>
                    <li>
                      ⑤ 회원은 회사가 제공하는 배너 광고에 대한 임의의 삭제,
                      비방, 기타 배너광고 방해행위 등을 할 수 없습니다.{' '}
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>제20조 (서비스의 중단 및 폐지) </h5>
                  <p>
                    회사는 다음 각 호의 사유가 발생하는 경우 서비스 휴지 또는
                    폐지 사유 및 휴지 또는 폐지일자 등을 서비스의 휴지 또는 폐지
                    전에 서비스 화면, 전자우편 등 회원이 명확하게 인식할 수 있는
                    방법으로 회원에게 통지함으로써 서비스를 휴지 또는 폐지할 수
                    있습니다. 다만, 제1호의 경우에는 휴지 또는 폐지 30일 전에
                    통지하여야 합니다.{' '}
                  </p>
                  <ol>
                    <li>
                      사업환경의 변화 또는 기술 발전 등으로 인하여 서비스를 계속
                      제공하는 것이 현저히 곤란한 경우
                    </li>
                    <li>
                      행정기관의 행정처분(방송통신위원회의 폐지나 휴지 승인을
                      포함)이나 법원의 판결, 결정 등으로 인하여 서비스를 계속
                      제공할 수 없는 경우{' '}
                    </li>
                    <li>
                      전시, 사변, 천재지변 기타 불가항력적 사유로 서비스를
                      제공하는 것이 현저히 곤란한 경우{' '}
                    </li>
                  </ol>
                </li>
                <li>
                  <h4>제 4 장 계약당사자의 의무</h4>
                  <ol>
                    <li>
                      <h5>제21조 (회사의 의무)</h5>
                      <ol>
                        <li>
                          ① 회사는 서비스 제공과 관련하여 알고 있는 회원의
                          개인정보를 본인의 동의 없이 제3자에게 누설, 배포,
                          제공하지 않습니다. 단, 관계법령에 의한 수사상의
                          목적으로 관계기관으로부터 요구 받은 경우 등 법률의
                          규정에 따른 적법한 절차에 의한 경우에는 그러하지
                          않습니다.{' '}
                        </li>
                        <li>
                          ② 회사는 관계법령과 본 약관에서 금지하거나 미풍양속에
                          반하는 행위를 하지 않으며, 계속적이고 안정적으로
                          서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                        </li>
                        <li>
                          ③ 회사는 회원이 안전하게 서비스를 이용할 수 있도록
                          개인정보 보호를 위한 보안시스템을 갖추어야 하며,
                          개인정보처리방침을 공시하고 준수합니다.
                        </li>
                        <li>
                          ④ 회사는 정보통신망법, 통신비밀보호법, 전기통신사업법
                          등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.{' '}
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h5>제22조 (회원의 의무)</h5>
                      <ol>
                        <li>
                          ① 회원은 관계법령, 본 약관의 규정, 이용안내 및
                          서비스상에 공지한 주의사항, 회사가 통지하는 사항을
                          준수하여야 하며, 기타 회사의 업무에 방해되는 행위를
                          하여서는 안됩니다.{' '}
                        </li>
                        <li>
                          ② 회원은 회사의 사전 동의 없이 서비스를 이용하여
                          어떠한 영리행위도 할 수 없으며, 법에 저촉되는 자료를
                          배포 또는 게재할 수 없습니다.{' '}
                        </li>
                        <li>
                          ③ 회원은 유료서비스를 이용할 경우 적법한 방법에 의하여
                          이용요금을 결제하여야 하며, 이용요금은 성실히
                          납부되어야 합니다.{' '}
                        </li>
                        <li>
                          ④ 회원은 회사가 서비스 이용과 관련하여 필요한 사항을
                          회원에게 알릴 수 있도록 회원가입 시 연락 가능한
                          전자우편 주소를 제공하여야 하고, 회원의 정보가 변경된
                          경우에는 이를 회사에 즉시 알려야 하며, 회원이 변경
                          정보를 회사에 알리지 않아 발생하는 불이익에 대해
                          회사는 책임을 지지 않습니다.{' '}
                        </li>
                        <li>
                          ⑤ 회원은 본 약관에서 규정하는 사항, 서비스 초기화면
                          또는 공지사항 및 회사가 정한 운영정책 등 제반 정책이나
                          규정을 수시로 확인하여야 합니다.{' '}
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <h4>부칙</h4>
                  <ol>
                    <li>
                      <h5>제1조 (시행일)</h5>
                      <p>본 약관은 2024년 7월 26일부터 시행합니다. </p>
                    </li>
                  </ol>
                </li>
              </li>
            </ol>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TermsOfUse;
