import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './TermsAgreement.module.scss';

const TermsAgreement: React.FC = () => {
  const [agreements, setAgreements] = useState({
    chkAll: false,
    terms: false,
    privacy: false,
    location: false,
    promotions: false,
  });

  const navigate = useNavigate();

  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setAgreements((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    if (agreements.terms && agreements.privacy && agreements.promotions) {
      setAgreements((prevState) => ({
        ...prevState,
        chkAll: true,
      }));
    } else {
      setAgreements((prevState) => ({
        ...prevState,
        chkAll: false,
      }));
    }
  }, [
    agreements.terms,
    agreements.privacy,
    agreements.location,
    agreements.promotions,
  ]);

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setAgreements({
      chkAll: checked,
      terms: checked,
      privacy: checked,
      location: checked,
      promotions: checked,
    });
  };

  const handleProceed = () => {
    if (agreements.terms && agreements.privacy) {
      navigate('/accounts/signup');
    } else {
      alert('필수 항목에 모두 동의해야 합니다.');
    }
  };

  return (
    <div className={styles.container}>
      <Link to="/">
        <img
          src="/icon/voss_logo.png"
          alt="Main Logo"
          className={styles.logo}
        />
      </Link>
      <div className={styles.agreementBox}>
        <h1 className={styles.title}>이용약관 동의</h1>
        <ul className={styles.join_box}>
          <li className={styles.checkBox}>
            <ul className={styles.clearfix}>
              <li>
                이용약관, 개인정보 수집 및 이용, 위치정보 이용약관 (필수),
                프로모션 안내 메일 수신(선택)에 모두 동의합니다.
                <input
                  type="checkbox"
                  name="chkAll"
                  id="chkAll"
                  className={styles.chkAll}
                  checked={agreements.chkAll}
                  onChange={handleCheckAll}
                />
              </li>
            </ul>
          </li>
          <li className={styles.checkBox}>
            <ul className={styles.clearfix}>
              <li>이용약관 동의 (필수)</li>
              <li className={styles.checkBtn}>
                <input
                  type="checkbox"
                  name="terms"
                  checked={agreements.terms}
                  onChange={handleAgreementChange}
                />
              </li>
            </ul>
            <textarea readOnly>
              [제 1장 총칙] 제 1 조 (목적) 이 약관은 주식회사 VOSS(이하 "회사"라
              합니다)이 제공하는 인터넷 관련 제반 서비스(이하 "서비스"라 한다)를
              이용함에 있어 회사와 이용자와의 권리, 의무 및 책임 사항, 기타
              필요한 사항을 규정하는데 목적이 있습니다. 제 2 조 (약관의 명시,
              효력 및 개정) ① 이 약관의 내용은 VOSS 통합 회원”이 쉽게 알 수
              있도록 서비스 화면에 게시합니다. ② 회사는 필요하다고 인정되는 경우
              본 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자
              및 변경사유를 명시하여 제1항과 같은 방법으로 그 적용일자 30일
              전일까지 공지합니다. 다만, 회원에게 불리한 약관의 변경인 경우에는
              로그인시 동의화면, E-mail, SMS 등으로 회원에게 개별 통지합니다.
              단, 회원의 연락처 미기재, 변경 후 미수정 등으로 인하여 개별 통지가
              어려운 경우에 한하여 본 항의 공지를 함으로써 개별 통지 한 것으로
              간주합니다. ③ 회사가 제 2 항에 따라 변경 약관을 공지 또는
              통지하면서, 회원에게 약관 변경 적용일까지 거부의사를 표시하지
              아니할 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지
              또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를
              표시하지 아니하면, 회원이 변경 약관에 동의한 것으로 간주합니다.
              회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고
              이용 계약을 해지할 수 있습니다. ③ 회사가 제 2 항에 따라 변경
              약관을 공지 또는 통지하면서, 회원에게 약관 변경 적용일까지
              거부의사를 표시하지 아니할 경우, 약관의 변경에 동의한 것으로
              간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관
              변경에 대한 거부의사를 표시하지 아니하면, 회원이 변경 약관에
              동의한 것으로 간주합니다. 회원은 변경된 약관 사항에 동의하지
              않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다. 제 3
              조 (약관 이외의 준칙) ① 이 약관에 언급되지 않은 사항은
              전기통신기본법, 전기통신사업법, 정보통신망이용촉진 및 정보보호
              등에 관한 법률 및 기타 관련 법령 또는 상관례에 따릅니다. ② 이
              약관은 회사가 제공하는 개별서비스에 관한 이용안내와 함께
              적용합니다. [제3장 계약 당사자의 의무] 제 10 조 (회사의 의무) ①
              회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를
              위한 보안 시스템을 갖추어야 하며, 유지·점검 또는 복구 등의 조치를
              성실히 이행하여야 합니다. ② 회사는 서비스의 제공과 관련하여 알게
              된 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지
              않고 이를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관한
              기타의 사항은 정보통신망법 및 회사가 별도로 정한
              개인정보취급방침에 따릅니다. ③ 회사는 회원이 수신 동의를 하지 않은
              영리 목적의 광고성 전자우편, SMS 문자메시지 등을 발송하지
              아니합니다. ④ 회사가 제3자와의 서비스 제공계약 등을 체결하여
              회원에게 서비스를 제공하는 경우 회사는 각 개별서비스에서 서비스의
              제공을 위하여 제3자에게 제공되는 회원의 구체적인 회원정보를
              명시하고 회원의 개별적이고 명시적인 동의를 받은 후 동의의 범위
              내에서 해당 서비스의 제공 기간 동안에 한하여 회원의 개인정보를
              제3자와 공유하는 등 관련 법령을 준수합니다. ⑤ 회사는 회원으로부터
              제기되는 불만이 정당하다고 인정할 경우에는 이를 즉시 처리함을
              원칙으로 합니다. 다만, 즉시 처리가 곤란한 경우에는 회원에게 그
              사유와 처리 일정을 통보합니다. 제 11 조 (회원의 의무) ① '회원'은
              관계법령, 이 약관의 규정, 이용안내 및 주의사항 등 '회사'가 공지
              또는 통지하는 사항을 준수해야 하며, 기타 '회사'의 업무에 방해되는
              행위를 할 수 없습니다. ② '회원'은 '회사'의 사전 승낙 없이 서비스를
              이용하여 어떠한 영리 행위도 할 수 없습니다. ③ '회원'은 서비스를
              이용하여 얻은 정보를 '회사'의 사전 승낙 없이 복사, 복제, 변경,
              번역, 출판, 방송 및 기타의 방법으로 사용하거나 이를 타인에게
              제공할 수 없습니다. ④ '회원'은 사진을 포함한 이미지 사용시
              피사체에 대한 초상권, 상표권, 특허권 및 기타 권리를 자신이
              취득해야 하며 만일 이들 권리에 대한 분쟁이 발생할 경우 회원이 모든
              책임을 부담해야 합니다. ⑤ 회원은 서비스 이용 시 다음 각 호의
              행위를 하지 않아야 합니다.가. 다른 회원의 ID 및 개인정보를 수집,
              저장하여 부정하게 사용하는 행위나. 서비스에서 얻은 정보를 회사의
              사전 승낙 없이 회원의 이용 이외의 목적으로 복제 하거나 이를 변경,
              출판 및 방송 등에 사용하거나 타인에게 제공하는 행위다. 회사의
              저작권, 타인의 저작권 등 기타 권리를 침해하는 행위라. 공공질서 및
              미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는
              행위마. 범죄와 결부된다고 객관적으로 판단되는 행위바. 타인의
              명예를 훼손하거나 모욕하는 행위사. 해킹 또는 컴퓨터바이러스를
              유포하는 행위아. 광고 또는 광고성 정보를 전송하거나 기타 영업을
              위한 행위자. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는
              일체의 행위차. 기타 관계 법령에 위배되는 행위 ⑥ 회원은 관계법령,
              이 약관에서 규정하는 사항, 서비스 이용 안내 및 주의 사항을
              준수하여야 합니다. [제 5 장 기 타] 제 21 조 (양도금지) '회원'이
              서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도하거나
              담보로 제공할 수 없습니다. 제 22 조 (청소년보호) 회사는 모든
              연령대가 자유롭게 이용할 수 있는 공간으로써 유해 정보로부터
              청소년을 보호하고 청소년의 안전한 인터넷 사용을 돕기 위해
              정보통신망법에서 정한 청소년보호정책을 별도로 시행하고 있으며,
              구체적인 내용은 서비스 초기 화면 등에서 확인할 수 있습니다. 제 23
              조 (면책조항) ① 회사는 천재지변 또는 이에 준하는 불가항력으로
              인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
              면제됩니다. ② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에
              대하여는 책임을 지지 않습니다. ③ 회사는 회원이 서비스와 관련하여
              게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 책임을
              지지 않습니다. ④ 회사는 회원 간 또는 회원과 제 3자 상호간에
              서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. ⑤
              회사는 회원의 이 약관상에 정하여진 의무 위반으로 인하여 회원 및
              제3자에게 발생한 손해에 대해서는 책임을 부담하지 아니합니다. 제 24
              조 (분쟁의 해결 및 관할법원) ① '회사'와 '회원'은 서비스와 관련하여
              발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 해야
              합니다. ② 제1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이
              제기될 경우에는 '회사'의 소재지를 관할하는 법원을 관할법원으로
              합니다. ③ '회사'와 이용자간에 제기된 전자거래 소송에는
              대한민국법을 준거법으로 합니다.
            </textarea>
          </li>
          <li className={styles.checkBox}>
            <ul className={styles.clearfix}>
              <li>개인정보 수집 및 이용에 대한 안내 (필수)</li>
              <li className={styles.checkBtn}>
                <input
                  type="checkbox"
                  name="privacy"
                  checked={agreements.privacy}
                  onChange={handleAgreementChange}
                />
              </li>
            </ul>
            <textarea readOnly>
              수집항목 및 수집방법 개인정보 수집 및 이용에 관한 동의사항
              주식회사 VOSS(이하 "회사")은 고객들(이하 “고객”)의 개인정보를
              중요시하며 '개인정보보호법', '정보통신망 이용촉진 및 정보보호 등에
              관한 법률' 등 관계법령을 준수하고 있습니다. 회사는 VOSS 관련 추가
              서비스(이하 "서비스") 이용을 위한 계약 상담, 신청, 문의사항 응대를
              위해 아래와 같이 개인정보를 수집 및 이용합니다. 1. 개인정보의
              수집·이용목적 회사는 다음과 같은 목적으로 고객의 개인정보 항목을
              수집 및 이용하고 있습니다. ① 서비스 제공 계약 체결 관련 상담 ②
              서비스 제공 계약 조건 확인을 위한 본인식별 및 실명확인 ③ 기존 회원
              여부 확인 ④ 이벤트 등의 응모시 확인 2. 수집하는 개인정보의 항목 ①
              고객명, 성별, 연령대, E-mail, 전화번호, 핸드폰번호 ② 서비스 이용
              과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어
              수집될 수 있습니다.- IP Address, 쿠키, 방문 일시, 서비스 이용
              기록, 불량 이용 기록 ③ 부가서비스 및 맞춤 서비스 이용 또는 이벤트
              응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은 정보들이
              수집될 수 있습니다.- 개인정보 추가 수집에 대한 동의를 받은 경우-
              이벤트 응모시 : 배송 주소, 전화번호 ④ 유료 서비스 이용 과정에서
              아래와 같은 결제 정보들이 수집될 수 있습니다- 신용카드 결제시 :
              카드사명, 카드번호 등- 휴대전화 결제시 : 이동전화번호, 통신사,
              결제승인번호- 계좌이체시 : 은행명, 계좌번호 등- 상품권 이용시 :
              상품권 번호 (자동이체 결제시 개인정보 수집은 ‘금융실명거래 및
              비밀보장에 관한 법률’에 의거 합니다.) 3. 개인정보의 보유 및
              이용기간 개인정보는 원칙적으로 개인정보의 수집·이용목적이
              달성되거나 고객의 요청이 있으면 지체 없이 파기 합니다. 단, 다음의
              정보에 대해서는 아래의 이유로 명시한 기간 동안 보존 합니다 ① 회사
              내부 방침에 의한 정보보유- 서비스 신청, 상담 및 문의사항에 대한
              상담 내용- 보존 이유 : 상담 이력 확인 및 분쟁 발생에 대한 소명자료
              활용- 보존 기간 : 목적달성 후 파기 ② 관련법령에 의한 정보보유 사유
              전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률 등
              관계법령에 의하여 보존할 필요가 있는 경우 당해 법령에서 정한 기간
              동안 개인정보를 보존합니다. 이 경우 회사는 보존하는 정보를 그
              보존의 목적으로만 이용합니다. 고객은 회사의 개인정보 수집 및 이용
              동의를 거부할 수 있습니다. 단, 동의를 거부하는 경우 서비스신청 및
              문의하기 등록이 정상적으로 완료될 수 없음을 알려드립니다. 수집 및
              이용목적 1. 개인정보의 수집·이용목적 회사는 다음과 같은 목적으로
              고객의 개인정보 항목을 수집 및 이용하고 있습니다. ① 서비스 제공
              계약 체결 관련 상담 ② 서비스 제공 계약 조건 확인을 위한 본인식별
              및 실명확인 ③ 기존 회원 여부 확인 ④ 이벤트 등의 응모시 확인 2.
              수집하는 개인정보의 항목 ① 고객명, 성별, 연령대, E-mail, 전화번호,
              핸드폰번호 ② 서비스 이용 과정이나 사업처리 과정에서 아래와 같은
              정보들이 자동으로 생성되어 수집될 수 있습니다.- IP Address, 쿠키,
              방문 일시, 서비스 이용 기록, 불량 이용 기록 ③ 부가서비스 및 맞춤
              서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에
              한해서만 아래와 같은 정보들이 수집될 수 있습니다.- 개인정보 추가
              수집에 대한 동의를 받은 경우- 이벤트 응모시 : 배송 주소, 전화번호
              ④ 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수
              있습니다- 신용카드 결제시 : 카드사명, 카드번호 등- 휴대전화 결제시
              : 이동전화번호, 통신사, 결제승인번호- 계좌이체시 : 은행명,
              계좌번호 등- 상품권 이용시 : 상품권 번호 (자동이체 결제시 개인정보
              수집은 ‘금융실명거래 및 비밀보장에 관한 법률’에 의거 합니다.) 보유
              및 이용기간 ① 회사 내부 방침에 의한 정보보유- 서비스 신청, 상담 및
              문의사항에 대한 상담 내용- 보존 이유 : 상담 이력 확인 및 분쟁
              발생에 대한 소명자료 활용- 보존 기간 : 회원 탈퇴 시 까지
            </textarea>
          </li>
          <li className={styles.checkBox}>
            <ul className={styles.clearfix}>
              <li>이벤트 등 프로모션 알림 메일 수신 (선택)</li>
              <li className={styles.checkBtn}>
                <input
                  type="checkbox"
                  name="promotions"
                  checked={agreements.promotions}
                  onChange={handleAgreementChange}
                />
              </li>
            </ul>
            <textarea readOnly>
              정보통신망법 규정에 따라 VOSS에 회원가입 신청하시는 분들 중 광고성
              정보 수신 동의에 대한 내용을 안내 드리오니 자세히 읽은 후 동의하여
              주시기 바랍니다. VOSS 광고성 정보 수신동의에 동의하신 회원분들의
              개인정보를 활용하여 전자식 전송매체(SMS/MMS/ E-mail 등 다양한
              전송매체)를 통해 VOSS 및 제휴업체의 상품 또는 서비스에 대한 광고성
              정보를 전송할 수 있습니다. 본동의는 거부할 수 있습니다. 다만 거부
              시 동의를 통해 제공 가능한 각종 우대 서비스, 혜택, 경품 및 이벤트
              안내를 받아 볼 수 없습니다. 고객이 본 수신동의를 철회하고자 할
              경우에는 고객센터(00-000-000)로 연락하요 수신동의 철회 요청을 할
              수 있습니다.
            </textarea>
          </li>
        </ul>
        <ul className={styles.footBtwrap}>
          <li>
            <button className={styles.fpmgBt2} onClick={handleProceed}>
              동의
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsAgreement;
